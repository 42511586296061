import { FC, useEffect, useState } from 'react';

import { Tabs, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks';

import Div from 'components/common/Div';
import LinkTab from 'components/common/LinkTab';
import ReportFiltersButton from 'components/common/ReportFiltersButton';
import SmartFilter from 'components/common/SmartFilter';
import { pickFieldsForSmartFilter } from 'components/common/SmartFilter/service';
import { ISmartFilter } from 'components/common/SmartFilter/types';
import TabPanel from 'components/common/TabPanel';

import { ReportType } from 'domain/report/filter/enums';
import { IReportFilter } from 'domain/report/filter/types';
import { IReportVacationsSmartFilters } from 'domain/report/vacation/apiSlice';

import { VacationsTab } from 'enums/reportVacations';

import { usePermissions } from 'hooks/usePermissions';

import { getCurrentYear } from 'utils/calendar';

import Calendar from './components/Calendar';
import VacationRequests from './components/VacationRequests';
import { VacationRequestState } from './enums';
import { getDefaultTabName, isCalendarTabActive, isVacationRequestsTabActive } from './service';
import styles from './styles';

const FILTERS: Array<ISmartFilter> = ['resource', 'project', 'technology', 'group', 'state'];
const VACATION_REQUEST_FILTERS: Array<ISmartFilter> = [
  'resource',
  'project',
  'technology',
  'group',
  'vacationRequestState',
];
const VACATION_TAB_LABEL = 'vacationsInfo';
const REPORT_TYPE = ReportType.trackedTimeEntries;

const getSmartFilterByTab = (tabName: VacationsTab) =>
  isVacationRequestsTabActive(tabName) ? VacationRequestState.Indefinite : null;

const getFilterByTab = (tabName: VacationsTab) =>
  isVacationRequestsTabActive(tabName) ? VACATION_REQUEST_FILTERS : FILTERS;

const Vacations: FC = () => {
  const { t } = useTranslation('reportVacations');
  const { queryStringUpdate, camelizedQuery, location } = useRouter();

  const { isRoleFeatureAllowed } = usePermissions();

  const [forYear, setForYear] = useState<number>(getCurrentYear());
  const [filters, setFilters] = useState<ISmartFilter[]>(FILTERS);
  const [currenTabName, setCurrentTabName] = useState<VacationsTab>(getDefaultTabName(location.hash));

  const [smartFilter, setSmartFilter] = useState<IReportVacationsSmartFilters>({
    ...pickFieldsForSmartFilter(camelizedQuery, getFilterByTab(currenTabName)),
  });

  const hasAccessToData = isRoleFeatureAllowed('canViewVacationsReport');

  const handleTabChange = (_: React.SyntheticEvent, newTab: VacationsTab) => {
    setSmartFilter(previousState => ({ ...previousState, stateEq: getSmartFilterByTab(newTab) }));
    setFilters(getFilterByTab(newTab));
    setCurrentTabName(newTab);
  };

  const handleSmartFiltersChange = (filters: IReportVacationsSmartFilters | null) => {
    if (_.isNil(filters)) return;

    setSmartFilter(filters);
  };

  const handleFiltersChange = (reportFilters: IReportFilter | null) => {
    if (_.isNil(reportFilters)) return;

    const filter = JSON.parse(reportFilters.filter);
    setSmartFilter(filter);
  };

  useEffect(() => {
    queryStringUpdate(smartFilter as Record<string, unknown>);
  }, [smartFilter]);

  return (
    <>
      <Div sx={styles.titleBlock}>
        <Typography variant="h1">{t('title')}</Typography>
        <ReportFiltersButton onChange={handleFiltersChange} currentReportType={REPORT_TYPE} />
      </Div>

      <Div sx={styles.smartFilter}>
        <SmartFilter<IReportVacationsSmartFilters>
          smartFilter={smartFilter}
          onSmartFilterChange={handleSmartFiltersChange}
          filters={filters}
          reportType={REPORT_TYPE}
        />
      </Div>

      {hasAccessToData ? (
        <Div sx={styles.container}>
          <Div sx={styles.tabHeadersContainer}>
            <Tabs value={currenTabName} onChange={handleTabChange} aria-label="tabs" textColor="inherit">
              <LinkTab
                label={t('tabs.vacationRequests.label')}
                value={VacationsTab.vacationRequests}
                blockLabel={VACATION_TAB_LABEL}
                to={{ path: location.pathname, hash: VacationsTab.vacationRequests, search: location.search }}
              />
              <LinkTab
                label={t('tabs.calendar.label')}
                value={VacationsTab.calendar}
                blockLabel={VACATION_TAB_LABEL}
                to={{ path: location.pathname, hash: VacationsTab.calendar, search: location.search }}
              />
            </Tabs>
          </Div>
          {isVacationRequestsTabActive(currenTabName) && (
            <TabPanel value={currenTabName} name={VacationsTab.vacationRequests} tabGroupName={VACATION_TAB_LABEL}>
              <VacationRequests smartFilter={smartFilter} />
            </TabPanel>
          )}
          {isCalendarTabActive(currenTabName) && (
            <TabPanel value={currenTabName} name={VacationsTab.calendar} tabGroupName={VACATION_TAB_LABEL}>
              <Calendar year={forYear} setYear={setForYear} smartFilter={smartFilter} />
            </TabPanel>
          )}
        </Div>
      ) : (
        <Typography textAlign="center">{t('common:noAccessToData')}</Typography>
      )}
    </>
  );
};

export default Vacations;
